.mapboxgl-ctrl-top-right {
    margin-top: 78px;
    margin-right: 10px;
  }
  @media (max-width: 960px) {
    .mapboxgl-ctrl-top-right {
      margin-top: 10px;
      margin-right: 10px;
      z-index: 3;
    }
  }
dt {
  font-weight: bold;
}
.mapboxgl-ctrl-bottom-right{
  bottom:11px;
  color: white;
}
.mapboxgl-ctrl-top-right .mapboxgl-ctrl {
  margin: 0px;
}
.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
  background-color: #F3BF00;
  background-image: url('svg/icon+.svg') !important;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
  background-color: #F3BF00;
  background-image: url('svg/icon-.svg') !important;
}
.mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
  background-image: url('svg/iconcompass.svg')!important;
}
.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon {
  background-color: #F3BF00;
  background-image: url('svg/geolocate.svg') !important;
}
.mapboxgl-ctrl button.mapboxgl-ctrl-fullscreen .mapboxgl-ctrl-icon{
  background-color: #F3BF00;
  background-image: url('svg/fullscreen.svg') !important;
}
.maplibregl-export-control {
  background-image: url('svg/export.svg')!important;
}

.mapboxgl-ctrl-group, .maplibregl-ctrl-group { 
  background-color: transparent;
}

.mapboxgl-ctrl-group button+button{
  border-top: none;
}

.mapboxgl-ctrl-group:not(:empty){
  box-shadow: none;
}

.displayNone {
  display: none !important;
}

.maplibregl-ctrl-rotate {
  background-color: #F3BF00 !important;
  height: 30px !important;
  width: 30px !important;
  border-color: transparent;
  padding:0px;
}